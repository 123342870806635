/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-03",
    versionChannel: "nightly",
    buildDate: "2024-02-03T00:16:11.299Z",
    commitHash: "28dc164d18c38cc6fd13c6339ad342fe5409cd55",
};
